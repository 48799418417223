import * as $ from "jquery";

// angular imports
import { NgModule, APP_INITIALIZER, ErrorHandler } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule, Title } from "@angular/platform-browser";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Route } from "@angular/router";
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from "@ngx-translate/core";
import { ToastrModule } from "ngx-toastr";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ClipboardModule } from 'ngx-clipboard';

// kendo ui for angular
import { GridModule } from "@progress/kendo-angular-grid";
import { IntlModule, load, IntlService } from '@progress/kendo-angular-intl';
import { DropDownsModule, MultiSelectModule } from "@progress/kendo-angular-dropdowns";
import { TextBoxModule, TextAreaModule, NumericTextBoxModule, MaskedTextBoxModule } from "@progress/kendo-angular-inputs";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs"
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogModule } from "@progress/kendo-angular-dialog";

// FontAwesome
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';

// Interceptors
import { InjectHeadersInterceptor } from "./interceptors/injectHeaders.interceptor";
import { RequestToJsonInterceptor } from "./interceptors/requestToJson.interceptor";
import { ResponseToPoJoInterceptor } from "./interceptors/responseToPoJo.interceptor";
import { AddAuthorizationHeaderInterceptor } from './interceptors/addAuthorizationHeader.interceptor';

// Components
import { AppComponent } from "./app.component";
import { NavComponent } from "./components/nav.component";
import { ContextComponent } from "./components/context.component";
import { MenuComponent } from "./components/menu.component";

// Shared module
import { SharedModule } from "./modules/shared/shared.module";

// Router components
import { LogonComponent } from "./routeComponents/logon/logon.component";
import { LogoffComponent } from "./routeComponents/logoff/logoff.component";
import { SessionExpiredComponent } from "./routeComponents/sessionExpired/sessionExpired.component";
import { OidcLogoutComponent } from "./routeComponents/oidc-logout/oidc-logout.component";
import { InitComponent } from "./routeComponents/init.component";
import { DashboardComponent } from "./routeComponents/dashboard.component";
import { NotAuthorizedComponent } from "./routeComponents/notAuthorized.component";
import { UserSearchComponent } from "./routeComponents/user/userSearch.component";
import { UserDetailComponent } from "./routeComponents/user/userDetail.component";
import { UserAccessDetailComponent } from "./routeComponents/user/userAccessDetail.component";
import { UserprofileDetailComponent } from "./routeComponents/userprofile/userprofileDetail.component";
import { LoggedOutComponent } from "./routeComponents/logged-out/loggedOut.component";

// Services & Providers
import { AnalyticsService } from "./services/analytics.service";
import { ConfigurationService } from "./services/configuration.service";
import { LanguageService } from "./services/language.service";
import { HttpProvider } from "./services/http.provider";
import { MenuService } from "./services/menu.service";
import { FilterCacheService } from "./services/filterCache.service";
import { LogService } from "./services/log.service";
import { MainContextService } from "./services/mainContext.service";
import { SecurityService } from "./services/security.service";
// do not provide this anymore ... use fpCacheServiceFactory
//import { CacheService, LocalStorageServiceModule } from "ng2-cache-service";
import { ErrorHandlingService } from "./services/errorHandling.service";
import { LookupDataUtilitiesService } from "./services/lookupDataUtilities.service";
import { CanDeactivateGuardService } from "./services/canDeactivateGuard.service";
import { AuthorizationGuardService } from "./services/authorizationGuard.service";
import { FpIntlService } from "./services/fpIntlService";
import { FpCacheServiceFactory } from "./services/fpCacheService.service";
import { GlobalErrorHandler } from './services/globalErrorHandler.service';
import { AuthenticationService } from './services/authentication.service';
import { LeavePageService } from './services/leavePage.service';
import { RegistrationService } from "./services/registration.service";
import { DemoPagesGuardService } from "./services/demoPagesGuard.service";

// Utilities
import { AppSettingsLoader } from "./utilities/appSettingsLoader"
import { OidcConfigLoader } from "./utilities/oidcConfigLoader";
import { WebApiTranslateLoader } from "./utilities/translate/webApiTranslateLoader";
import { FpMissingTranslationHandler } from "./utilities/translate/missingTranslationHandler";
import { MyFostErrorHandler } from "./classes/errorHandling"

// Directives
import { FpParentNodeClickDirective } from "./directives/fpParentNodeClick.directive";

// Repositories
import { LookupDataRepository } from "./repositories/lookupData.repository";
import { OrganisationGroupRepository } from "./repositories/organisationGroup.repository";
import { UserRepository } from "./repositories/user.repository";
import { PartyRepository } from "./repositories/party.repository";
import { ContractRepository } from "./repositories/contract.repository";
import { ItsMeRepository } from "./repositories/itsMe.repository";

// File upload
import { FileUploadModule } from "ng2-file-upload";

// OIDC
import { AuthModule, PublicEventsService, EventTypes, StsConfigLoader, OidcClientNotification } from 'angular-auth-oidc-client';
import { tap, filter } from 'rxjs/operators';

var suplLikelySubtags = require("cldr-data/supplemental/likelySubtags.json");
var suplCurrencyData = require("cldr-data/supplemental/currencyData.json");
var suplweekData = require("cldr-data/supplemental/weekData.json");
var numberNl = require("cldr-data/main/nl-BE/numbers.json");
var currenciesNl = require("cldr-data/main/nl-BE/currencies.json");
var dateFieldsNl = require("cldr-data/main/nl-BE/dateFields.json");
var caGregorianNl = require("cldr-data/main/nl-BE/ca-gregorian.json");
var timeZoneNl = require("cldr-data/main/nl-BE/timeZoneNames.json");
var numberFr = require("cldr-data/main/fr-BE/numbers.json");
var currenciesFr = require("cldr-data/main/fr-BE/currencies.json");
var dateFieldsFr = require("cldr-data/main/fr-BE/dateFields.json");
var caGregorianFr = require("cldr-data/main/fr-BE/ca-gregorian.json");
var timeZoneFr = require("cldr-data/main/fr-BE/timeZoneNames.json");

declare var myApp: any;

var rootUrl: string = $('#linkRoot').attr('href');
var appName: string = 'MyFost';

// commonRoutePrefix is needed when putting organisationGroup and selectedPartyId in url's
//var commonRoutePrefix = ":organisationGroupId/:selectedPartyId/";
var commonRoutePrefix = "";
var routes: Route[] = [
    {
        path: '',
        component: InitComponent,
        pathMatch: 'full',
        canActivate: [AuthorizationGuardService]
    },
    {
        path: 'unauthorized',
        component: NotAuthorizedComponent,
        canDeactivate: [CanDeactivateGuardService]
    },
    {
        path: 'logon',
        component: LogonComponent,
        canDeactivate: [CanDeactivateGuardService]
    },
    {
        path: 'logoff',
        component: LogoffComponent,
        canDeactivate: [CanDeactivateGuardService]
    },
    {
        path: 'logged-out',
        component: LoggedOutComponent
    },
    {
        path: 'sessionExpired',
        component: SessionExpiredComponent,
        canDeactivate: [CanDeactivateGuardService]
    },
    {
        path: 'oidc-logout',
        component: OidcLogoutComponent
    },
    {
        path: 'init',
        component: InitComponent,
        canActivate: [AuthorizationGuardService]
    },
    {
        path: 'home',
        component: DashboardComponent,
        canDeactivate: [CanDeactivateGuardService],
        canActivate: [AuthorizationGuardService]
    },
    //{
    //    path: commonRoutePrefix + 'dashboard',
    //    component: DashboardComponent,
    //    canActivate: [AuthorizationGuardService]
    //},
    {
        path: commonRoutePrefix + 'user/search',
        component: UserSearchComponent,
        canActivate: [AuthorizationGuardService]
    },
    {
        path: commonRoutePrefix + 'user/new',
        component: UserDetailComponent,
        canDeactivate: [CanDeactivateGuardService],
        canActivate: [AuthorizationGuardService]
    },
    {
        path: commonRoutePrefix + 'user/:id',
        component: UserDetailComponent,
        canDeactivate: [CanDeactivateGuardService],
        canActivate: [AuthorizationGuardService]
    },
    {
        path: commonRoutePrefix + 'userAccess/:id',
        component: UserAccessDetailComponent,
        canDeactivate: [CanDeactivateGuardService],
        canActivate: [AuthorizationGuardService]
    },  
    {
        path: commonRoutePrefix + 'userprofile',
        component: UserprofileDetailComponent,
        canDeactivate: [CanDeactivateGuardService],
        canActivate: [AuthorizationGuardService]
    },
    {
        path: 'registration',
        loadChildren: () => import('./modules/registration/registration.module').then(m => m.RegistrationModule)
    },
    {
        path: 'demo',
        loadChildren: () => import('./modules/demo/demo.module').then(m => m.DemoModule)
    },
    {
        path: 'userIAMRegistration',
        loadChildren: () => import('./modules/userIAMRegistration/userIAMRegistration.module').then(m => m.UserIAMRegistrationModule)
    },
    {
        path: 'userCreate',
        loadChildren: () => import('./modules/userCreate/userCreate.module').then(m => m.UserCreateModule)
    },
    {
        path: '**', component: InitComponent, pathMatch: 'full'
    }
];

//export class MyFostToastOptions extends ToastOptions {
//    dismiss = "auto";
//    toastLife = 3000;
//    newestOnTop = false;
//    showCloseButton = false;
//    maxShown = 5;
//    positionClass = "toast-top-right";
//    animate = "fade";
//    enableHTML = false;
//}

// Force locale fr-BE to use '.' as thousand separator
numberFr.main["fr-BE"].numbers["symbols-numberSystem-latn"].group = ".";

load(
    suplLikelySubtags,
    suplCurrencyData,
    suplweekData,

    numberNl,
    currenciesNl,
    dateFieldsNl,
    caGregorianNl,
    timeZoneNl,
    
    numberFr,
    currenciesFr,
    dateFieldsFr,
    caGregorianFr,
    timeZoneFr,
);

import {
    faCircleArrowLeft,
    faCircleArrowRight,
    faCompress,
    faExchangeAlt,
    faExpand,
    faInfoCircle,
    faArrowsRotate,
    faTrashCan,
    faUser,
    faSearch,
    faCheck,
    faTimes,
    faPlus,
    faDownload,
    faPenToSquare,
    faEuroSign,
    faArrowRotateRight,
    faChevronLeft,
    faChevronRight,
    faExclamationCircle,
    faChevronDown,
    faPaperPlane
} from '@fortawesome/free-solid-svg-icons';

import {
    faSquare as farSquare,
    faSquareCheck as farSquareCheck,
    faFileLines as FarFileLines,
    faCopy as farCopy
} from '@fortawesome/free-regular-svg-icons';

@
NgModule({
    imports: [
        BrowserModule,
        ClipboardModule,
        FormsModule,
        RouterModule.forRoot(routes, { useHash: true }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpProvider) => new WebApiTranslateLoader(http,
                    rootUrl + 'api/translations/{lang}/{part}'),
                deps: [HttpProvider, 'rootUrl']
            },
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: FpMissingTranslationHandler }
        }),
        GridModule,
        ToastrModule.forRoot({
            autoDismiss: true,
            timeOut: 3000,
            newestOnTop: false,
            closeButton: false,
            maxOpened: 5,
            positionClass: "toast-top-right",
            //animate: "fade",
            enableHtml: false
        }),
        DropDownsModule,
        TextBoxModule,
        TextAreaModule,
        NumericTextBoxModule,
        MaskedTextBoxModule,
        MultiSelectModule,
        NoopAnimationsModule,
        DateInputsModule,
        ButtonsModule,
        DialogModule,
        FileUploadModule,
        IntlModule,
        FontAwesomeModule,
        SharedModule,
        HttpClientModule,
        AuthModule.forRoot({
            loader: {
                provide: StsConfigLoader,
                useFactory: (oidcConfigLoader: OidcConfigLoader) => {
                    return oidcConfigLoader.loadOidcConfig();
                },
                deps: [OidcConfigLoader, HttpProvider]
            }
        }),
    ],
    declarations: [
        AppComponent,
        NavComponent,
        MenuComponent,
        ContextComponent,
        DashboardComponent,
        NotAuthorizedComponent,
        LogonComponent,
        LogoffComponent,
        SessionExpiredComponent,
        OidcLogoutComponent,
        InitComponent,
        UserSearchComponent,
        UserDetailComponent,
        UserAccessDetailComponent,       
        UserprofileDetailComponent,
        FpParentNodeClickDirective,
        LoggedOutComponent
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        { provide: 'rootUrl', useValue: rootUrl },
        { provide: 'appName', useValue: appName },
        ConfigurationService,
        LogService,
        OidcConfigLoader,
        AppSettingsLoader,
        { provide: APP_INITIALIZER, useFactory: (appSettingsLoader: AppSettingsLoader) => () => appSettingsLoader.loadAppSettings(), deps: [AppSettingsLoader, HttpProvider, ConfigurationService, 'rootUrl'], multi: true },
        LanguageService,
        AnalyticsService,
        { provide: ErrorHandler, useClass: MyFostErrorHandler },
        FpCacheServiceFactory,
        Title,
        { provide: HTTP_INTERCEPTORS, useClass: AddAuthorizationHeaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: InjectHeadersInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: RequestToJsonInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ResponseToPoJoInterceptor, multi: true },
        HttpProvider,
        MenuService,
        FilterCacheService,
        MainContextService,
        SecurityService,
        ErrorHandlingService,
        LookupDataUtilitiesService,
        AuthenticationService,
        LeavePageService,
        CanDeactivateGuardService,
        AuthorizationGuardService,
        DemoPagesGuardService,
        { provide: IntlService, useClass: FpIntlService },
        // repositories
        OrganisationGroupRepository,
        UserRepository,
        PartyRepository,
        LookupDataRepository,
        ContractRepository,
        ItsMeRepository,
        RegistrationService
    ],
    exports: [
        FontAwesomeModule
    ]
})

export class AppModule {
    constructor(
        library: FaIconLibrary,
        private publicEventService: PublicEventsService,
        private logService: LogService) {

        library.addIcons(
            // FA solid icons
            faCircleArrowLeft, faCircleArrowRight, faCompress, faExchangeAlt, faExpand, faCheck, faPenToSquare,
            faEuroSign, faInfoCircle, faArrowsRotate, faPlus, faTimes, faExclamationCircle, faChevronLeft, faChevronRight,
            faChevronDown, faDownload, faSearch, faArrowRotateRight, faTrashCan, faUser, faPaperPlane,

            // FA regular icons
            FarFileLines, farSquare, farSquareCheck, farCopy
        );

        this.publicEventService.registerForEvents()
            .pipe(
                filter((event: OidcClientNotification<any>) => event.type == EventTypes.ConfigLoaded),
                tap((event) => this.logService.info('OIDC config loaded.'))
            ).subscribe();
    }
}