import { Injectable, Inject, EventEmitter } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { FpCacheServiceFactory, FpCacheService, FpCacheStoragesEnum } from "./fpCacheService.service";
import { LogService } from "./log.service";
import { Router, ActivatedRoute } from "@angular/router";

import { UserRepository } from "../repositories/user.repository";
import { Subject, Observable, Subscription } from "rxjs";
import { throttleTime } from 'rxjs/operators';

@Injectable()
export class SecurityService {
    constructor( @Inject("appName") private appName: string,
        private userRepository: UserRepository,
        private logService: LogService,
        private router: Router,
        public route: ActivatedRoute) {

        this._reloadSubscription = this._reloadSubject.asObservable()
            .pipe(throttleTime(200))
            .subscribe(x => this.reload());
    }

    private _organisationGroupId: number;
    private _userAccesses: Array<FostPlus.Olympus.UsersDomain.Clients.Api.IUserAccessDto>;
    private _reloadSubject: Subject<boolean> = new Subject<boolean>();
    private _reloadSubscription: Subscription;


    public onUserAccessRefresh: EventEmitter<boolean> = new EventEmitter<boolean>();

    public init(organisationGroupId: number, userAccesses: Array<FostPlus.Olympus.UsersDomain.Clients.Api.IUserAccessDto>) {
        this._organisationGroupId = organisationGroupId;
        
        this.reloaded(userAccesses);
    }

    private reloaded(userAccesses: Array<FostPlus.Olympus.UsersDomain.Clients.Api.IUserAccessDto>) {
        this._userAccesses = userAccesses;

        this.onUserAccessRefresh.emit(true);
    }

    public requestReload() {
        this._reloadSubject.next(true);
    }

    private reload() {
        this._userAccesses = [];
        this.userRepository.userAccessGet(this._organisationGroupId)
            .then((data) => {
                this.reloaded(data.userAccesses);

                this.logService.info("userAccess reloaded");
            })
            .catch((error) => {
                this.logService.error("userAccess reload failed");
            });
    }

    //public hasUserRead() {
    //    var hasRight = this.hasRight(null, UCEnums.LuResourceId.User, UCEnums.LuActionId.Read);

    //    return hasRight;
    //}

    public hasDeliveryValidate(senderPartyId: number) {
        var hasRight = this.hasRight(senderPartyId, FostPlus.Olympus.UsersDomain.Clients.Api.LuResourceId.Delivery, FostPlus.Olympus.UsersDomain.Clients.Api.LuActionId.Validate);

        return hasRight;
    }

    public hasWeighingTicketValidate(weighingPartyId: number) {
        var hasRight = this.hasRight(weighingPartyId, FostPlus.Olympus.UsersDomain.Clients.Api.LuResourceId.Delivery, FostPlus.Olympus.UsersDomain.Clients.Api.LuActionId.Validate);

        return hasRight;
    }

    private hasRight(partyId: number, luResouceId: FostPlus.Olympus.UsersDomain.Clients.Api.LuResourceId, luActionId: FostPlus.Olympus.UsersDomain.Clients.Api.LuActionId) {
        if (!this._userAccesses) {
            return false;
        }

        var i = this._userAccesses.findIndex((item) => {
            return (partyId == null || item.partyId == partyId)
                && item.luResourceId == luResouceId
                && item.luActionId == luActionId;
        });

        return i >= 0;
    }

    //private hasRight(senderPartyId: number, luResouceId: UCEnums.LuResourceId, luActionId: UCEnums.LuActionId) {
    //    var i = this._userAccesses.findIndex((item) => {
    //        return item.partyId == senderPartyId
    //            && item.luResourceId == luResouceId
    //            && item.luActionId == luActionId;
    //    });

    //    return i >= 0;
    //}
}