<form #myForm="ngForm" novalidate>
    <fp-error-display [errors]="errors"
                      [warnings]="warnings"></fp-error-display>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <h4 class="panel-title" fp-translate>
                    MyApp.User
                </h4>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="data.user">
        <div class="col-md-2">
            <fp-label label="Users.User_UserName"></fp-label>
        </div>
        <div class="col-md-2">
            <label class="control-normal-label">{{data.user.windowsLogon}}</label>
        </div>
        <div class="col-md-2">
            <fp-label label="Users.User_Email"></fp-label>
        </div>
        <div class="col-md-2">
            <label class="control-normal-label">{{data.user.email}}</label>
        </div>
    </div>
    <div class="row" *ngIf="data.user">
        <div class="col-md-2">
            <fp-label label="Users.User_FirstName"></fp-label>
        </div>
        <div class="col-md-2">
            <label class="control-normal-label">{{data.user.firstName}}</label>
        </div>
        <div class="col-md-2">
            <fp-label label="Users.User_LastName"></fp-label>
        </div>
        <div class="col-md-2">
            <label class="control-normal-label">{{data.user.lastName}}</label>
        </div>
    </div>
    <div class="row" *ngIf="data.user">
        <div class="col-md-2">
            <fp-label label="Users.User_LuLanguageId"></fp-label>
        </div>
        <div class="col-md-2">
            <label class="control-normal-label">{{data.user.$$languageName}}</label>
        </div>
        <div class="col-md-2">
            <fp-label label="Users.User_LuGenderId"></fp-label>
        </div>
        <div class="col-md-2">
            <label class="control-normal-label">{{data.user.$$genderSalutationShortName}}</label>
        </div>
    </div>
    <div class="row" *ngIf="data.user">
        <div class="col-md-2">
            <fp-label label="Users.User_Active"></fp-label>
        </div>
        <div class="col-md-2 activeIcon">
            <span *ngIf="data.user.isActive"><fa-icon [icon]="['far', 'square-check']"></fa-icon></span>
            <span *ngIf="!data.user.isActive"><fa-icon [icon]="['far', 'square']"></fa-icon></span>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <h4 class="panel-title" fp-translate>
                MyApp.Approvals
            </h4>
        </div>
    </div>
    <div class="row" *ngIf="data.userAccess">
        <div class="col-md-2">
            <label class="control-label">{{data.userAccess.$$party.$$luPartyTypeName}}</label>
        </div>
        <div class="col-md-6">
            <label class="control-normal-label">{{data.userAccess.$$party.name}}</label>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            &nbsp;
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <table class="table table-bordered table-super-condensed table-fixed">
                <thead>
                    <tr>
                        <th>
                            <fp-label label="Users.Authorizations"></fp-label>
                        </th>
                        <th>
                            <input type="checkbox"
                                   class="hand"
                                   name="SelectAll"
                                   [(ngModel)]="allRowsSelected"
                                   (change)="selectAllRows()"
                                   *ngIf="canEdit"/>
                        </th>
                    </tr>
                </thead>
                <tbody *ngIf="data.userAccess">
                    <tr *ngFor="let uara of data.userAccess.userAccessResourceActions;let even = even"
                        ngForm="uaRa.$$localId"
                        [class.k-alt]="even">
                        <td>
                            <label class="control-normal-label">{{uara.$$resourceName}}</label>
                        </td>
                        <td>
                            <fp-checkbox name="resourceAction"
                                         label="{{uara.$$actionName}}"
                                         [(ngModel)]="uara.hasAccess"
                                         [localId]="uara.$$localId"
                                         (toggle)="hasAccessChange(uara)"
                                         [readMode]="!canEdit"></fp-checkbox>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="btn-toolbar">
                <button class="btn btn-primary " (click)="saveChanges()" fp-translate *ngIf="canEdit">
                    <fa-icon icon="download"></fa-icon>
                    MyApp.Save_Changes
                </button>
                <button class="btn btn-danger " type="button" (click)="openDialog()" fp-translate *ngIf="canDelete">
                    <fa-icon icon="times"></fa-icon>
                    MyApp.Remove_PartnerMember_Approvals
                </button>
                <button class="btn btn-outline " type="button" (click)="historyBack()" fp-translate>
                    <fa-icon icon="circle-arrow-left" size="lg"></fa-icon>
                    MyApp.Back
                </button>
            </div>
        </div>
    </div>
    <fp-modal-dialog #removeDialog header="MyApp.Delete"
                     content="MyApp.Remove_PartnerMember_Approvals_Warning"
                     (result)="deleteAuthorizations($event)">
    </fp-modal-dialog>

    <div class="row">
        <div class="col-md-12">
            &nbsp;
        </div>
    </div>
    <!--debug info-->
    <fpDebugDisplay [isDebug]="isDebug">
        <div class="row">
            <div class="col-md-12">
                <pre>{{data | json}}</pre>
            </div>
        </div>
    </fpDebugDisplay>
</form>
