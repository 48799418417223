import { Injectable } from '@angular/core';
import { Router } from "@angular/router";

import UserContract = FostPlus.Olympus.UsersDomain.Clients.Api;

@Injectable({
    providedIn: 'root'
})
export class RegistrationService {
    private userId: number | null;

    constructor(private router: Router) { }

    public setUserId(userId: number | null) {
        this.userId = userId;
    }

    public getUserId() {
        return this.userId;
    }

    public navigateToLastIncomplete(userPartyRegistration: UserContract.IUserPartyRegistrationForFlowDto) {
        if (!userPartyRegistration.isStepPartyComplete) {
            this.navigateTo(1);
        } else if (!userPartyRegistration.isStepStartDateComplete) {
            this.navigateTo(2);
        } else if (!userPartyRegistration.isStepProfileComplete) {
            this.navigateTo(3);
        } else if (!userPartyRegistration.isStepActivityComplete) {
            this.navigateTo(4);
        } else if (!userPartyRegistration.isStepContractComplete) {
            this.navigateTo(5);
        } else if (!userPartyRegistration.isStepRequestComplete) {
            this.navigateTo(6);
        } else {
            // navigate to completion page
            this.navigateTo(0);
        }
    }

    public navigateTo(step: number) {
        switch (step) {
            case 1:
                // party step
                this.router.navigate(['registration/party']);
                break;
            case 2:
                // startDate step
                this.router.navigate(['registration/start-date']);
                break;
            case 3:
                // profile step
                this.router.navigate(['registration/profile']);
                break;
            case 4:
                // activity step
                this.router.navigate(['registration/activity']);
                break;
            case 5:
                // contract step
                this.router.navigate(['registration/contract']);
                break;
            case 6:
                // request step
                this.router.navigate(['registration/complete']);
                break;
            default:
                // navigate to registration completion page
                this.router.navigate(['']);
                break;
        }
    }
}
