<form #myForm="ngForm" novalidate>
    <div class="form-horizontal">
        <fp-error-display [errors]="errors"
                          [warnings]="warnings"></fp-error-display>
    </div>
    <div [hidden]="hide">
        <div class="row">
            <div class="col-lg-12">
                <h4 class="panel-title" fp-translate>
                    Users.User_Search
                </h4>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-md-2">
                    <div (click)="toggleFilters()" class="hand">
                        <label fp-translate class="control-label hand" style="display: inline;">
                            MyApp.Filters
                        </label>
                        <fa-icon class="fost"
                                 [icon]="filtersCollapsed ? 'chevron-right' : 'chevron-down'"></fa-icon>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!filtersCollapsed">
            <div class="row">
                <div class="col-md-2">
                    <fp-label label="Users.User_FirstName"></fp-label>
                    <fp-textbox name="Users.User_FirstName" [(ngModel)]="data.userFirstName"></fp-textbox>
                </div>
                <div class="col-md-2">
                    <fp-label label="Users.User_LastName"></fp-label>
                    <fp-textbox name="Users.User_LastName" [(ngModel)]="data.userLastName"></fp-textbox>
                </div>
                <div class="col-md-2">
                    <fp-label label="Users.User_Email"></fp-label>
                    <fp-textbox name="Users.User_Email" [(ngModel)]="data.userEmail"></fp-textbox>
                </div>
                <div class="col-md-2">
                    <fp-label label="Users.User_User_Name"></fp-label>
                    <fp-textbox name="Users.User_User_Name" [(ngModel)]="data.userWindowsLogon"></fp-textbox>
                </div>
                <div class="col-md-2 checkboxIsActive">
                    <fp-checkbox name="Users.User_Active"
                                 [(ngModel)]="data.userIsActive">
                    </fp-checkbox>
                </div>
                <div class="col-md-2 checkboxIsActive">
                    <fp-checkbox name="Users.User_Inactive"
                                 [(ngModel)]="data.userInActive">
                    </fp-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <fp-label label="Users.UserAccess_PartyId"></fp-label>
                    <fp-multiselect name="Users.UserAccess_PartyId"
                                    [(ngModel)]="data.partyFilterValues"
                                    [data]="orgGroupParties"
                                    textField="name"></fp-multiselect>
                </div>
            </div>
        </div>

        <!--buttons - search-->
        <div class="row" *ngIf="!filtersCollapsed">
            <div class="col-md-6">
                <div class="btn-toolbar">
                    <button class="btn btn-primary" (click)="search()" fp-translate>
                        <fa-icon icon="search"></fa-icon>
                        MyApp.Search
                    </button>
                    <button class="btn btn-outline" type="button" (click)="reset()" fp-translate>
                        <fa-icon icon="arrow-rotate-right"></fa-icon>
                        MyApp.Reset
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <kendo-grid fpGridBinding
                            #gridBinding="fpGridBinding"
                            uniqueId="BD290276-1076-4BAF-BF20-A0F8FC0AB5F7"
                            [itemKey]="selectedSiteKey"
                            [rebinder]="loadGridData"
                            [autoBindOnInit]="false"
                            [sortable]="true"
                            [pageable]="{
                                pageSizes: [10, 20, 50, 100]
                            }"
                            scrollable="none"
                            [selectable]="true"
                            (selectionChange)="gridSelectionChange($event)">
                    <ng-template kendoGridToolbarTemplate position="top">
                        <div class="toolbar-button" (click)="newUser()">
                            <fa-icon icon="plus"></fa-icon>
                            <span fp-translate>MyApp.NewUser</span>
                        </div>
                    </ng-template>

                    <kendo-grid-column field="firstName">
                        <ng-template kendoGridHeaderTemplate>
                            <span fp-translate>Users.User_FirstName</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span title="{{dataItem.firstName}}">{{dataItem.firstName}}</span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="lastName">
                        <ng-template kendoGridHeaderTemplate>
                            <span fp-translate>Users.User_LastName</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span title="{{dataItem.lastName}}">{{dataItem.lastName}}</span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="email">
                        <ng-template kendoGridHeaderTemplate>
                            <span fp-translate>Users.User_Email</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span title="{{dataItem.email}}">{{dataItem.email}}</span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="windowsLogon">
                        <ng-template kendoGridHeaderTemplate>
                            <span fp-translate>Users.User_WindowsLogon</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span title="{{dataItem.windowsLogon}}">{{dataItem.windowsLogon}}</span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="isActive">
                        <ng-template kendoGridHeaderTemplate>
                            <span fp-translate>Users.User_Active</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span *ngIf="dataItem.isActive"><fa-icon [icon]="['far', 'square-check']"></fa-icon></span>
                            <span *ngIf="!dataItem.isActive"><fa-icon [icon]="['far', 'square']"></fa-icon></span>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </div>
    </div>
    <div class="row">
        <!-- Buttons -->
        <div class="col-md-10">
            <div class="btn-toolbar">
                <button class="btn btn-outline" type="button" (click)="historyBack()">
                    <fa-icon icon="circle-arrow-left" size="lg"></fa-icon>
                    <span fp-translate>MyApp.Back</span>
                </button>
            </div>
        </div>
    </div>
</form>

