import { Component, OnInit, OnDestroy, ViewEncapsulation, ElementRef, Inject, ViewChild } from "@angular/core"
import { LanguageService } from "./services/language.service";
import { MainContextService } from "./services/mainContext.service";
import { AuthenticationService } from './services/authentication.service';
import { LeavePageService } from "./services/leavePage.service";
import { Router } from '@angular/router';
import { BlockUiService } from './services/blockUi.service';
import { Subscription } from "rxjs";

import { FpModalDialogComponent } from "./modules/shared/components/forms/fpModalDialog.component";

@Component({
    //moduleId: module.id.toString(),
    selector: "body",
    templateUrl: "./app.component.html",
    host: { '[class.fullScreen]': 'mainContextService.isFullScreen' },
    // The app.component also serves as the root BlockUiContainer.
    providers: [
        BlockUiService
    ]
})
export class AppComponent implements OnInit, OnDestroy {
    @ViewChild("leavePageDialog", { static: true }) private leavePageDialog: FpModalDialogComponent;

    private authSubscription: Subscription;

    public isAuthenticationDialogOpen = false;

    constructor(private hostElement: ElementRef,
        private languageService: LanguageService,
        public mainContextService: MainContextService,
        private blockUiService: BlockUiService,
        private router: Router,
        private authenticationService: AuthenticationService,
        private leavePageService: LeavePageService,
        @Inject("appName") public appName: string) {

        this.blockUiService.startBlocking();
        this.isAuthenticationDialogOpen = true;
    }

    ngOnInit(): void {        
        this.languageService.setInitialLanguage();  

        // Set host element as root blockUiContainer
        var e = <HTMLElement>this.hostElement.nativeElement;
        if (e) {
            this.blockUiService.blockElement = e;
        }

        // Save route for navigation after login
        var hash = window.location.hash;
        var urlParams = new URLSearchParams(window.location.search);
        // the LogonComponent handles the actual auto logon
        // the OidcLogoutComponent handles local logout in iframe when loged out on the server. (users should not navigate here)
        if (hash && false === hash.startsWith('#/logon') && false === hash.startsWith('#/oidc-logout') && false === hash.startsWith('#/logged-out')) {
            hash = hash.substr(2);
            window.sessionStorage.setItem('oidc_redirect', hash);
        }

        this.authSubscription = this.authenticationService.isAuthenticatedObservable.subscribe(
            (isAuthenticated: boolean) => {
                this.onAuthenticated(isAuthenticated);
            });

        if (hash.startsWith('#/logged-out')) {
            this.isAuthenticationDialogOpen = false;
        } else {
            this.authenticationService.checkAuth();
        }

        this.leavePageService.dialogOpenObs.subscribe(
            () => {
                if (this.leavePageDialog) {
                    this.leavePageDialog.showDialog();
                }
            }
        );
    }

    ngOnDestroy(): void {
        if (this.authSubscription) {
            this.authSubscription.unsubscribe()
        }
    }

    private onAuthenticated(isAuthenticated: boolean) {        
        this.blockUiService.startBlocking();

        if (isAuthenticated) {
            var path = window.sessionStorage.getItem('oidc_redirect');
            window.sessionStorage.setItem('oidc_redirect', null);
            if (!path || path === 'null') {
                path = 'init';
            }
            this.languageService.setInitialLanguage();

            if (false == this.authenticationService.userIdentity.isInternalUser
                && !this.authenticationService.userIdentity.organisationGroupId) {
                // its a user whom is going through the onboarding module
                this.blockUiService.stopBlocking();
                this.isAuthenticationDialogOpen = false;
                this.router.navigateByUrl('registration', { replaceUrl: true });
            } else if (path.startsWith('registration?userId=')) {
                this.blockUiService.stopBlocking();
                this.isAuthenticationDialogOpen = false;
                this.router.navigateByUrl(path, { replaceUrl: true });
            }
            else {
                // Try to load the organisationGroup
                var orgGroupfound = this.mainContextService.loadOrganisationGroup(null);

                this.blockUiService.stopBlocking();
                this.isAuthenticationDialogOpen = false;

                if (orgGroupfound || path.startsWith('init')) {
                    this.router.navigateByUrl(path)
                } else {
                    // => organisationGroup not found. Force navigation to init page.
                    this.router.navigateByUrl('init', { replaceUrl: true });
                }
            }
        } else {
            this.blockUiService.stopBlocking();
            this.isAuthenticationDialogOpen = false;

            if (!this.router.url.startsWith('/oidc-logout')) {
                this.router.navigate(['/unauthorized']);
            }
        }

        // unsubscribe. this function should only be run after initial authentication result
        if (this.authSubscription) {
            this.authSubscription.unsubscribe();
        }
    }

    public leavePageDialogResult(result: boolean): void {
        this.leavePageService.callLeavePageChoice(result);
    }
}

